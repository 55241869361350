import { Configuration, LogLevel } from '@azure/msal-browser';
import { environment } from 'environments/environment';

const clientId = environment.authAppClientId ? environment.authAppClientId : '6955cd20-eedc-42a9-b078-1d73e71912e2';
const tenantId = environment.authAppTenantId ? environment.authAppTenantId : 'common';
const redirectUri = environment.authAppRedirectUri ? environment.authAppRedirectUri : 'https://paymentcentralvnextdev.pmp.microsoft.com/bank/';

export const msalConfig: Configuration = {
    auth: {
        clientId: clientId, // This is your client ID
        authority: 'https://login.microsoftonline.com/' + tenantId, // This is your tenant ID
        redirectUri: redirectUri, // This is your redirect URI
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to true if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};
