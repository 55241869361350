import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Type, ModuleWithProviders, Provider, ErrorHandler, ElementRef, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_BASE_HREF } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { UploadModule } from '@progress/kendo-angular-upload';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { GridModule } from '@progress/kendo-angular-grid';
import { POPUP_CONTAINER } from '@progress/kendo-angular-popup';
import { AppRoutingModule } from 'app/app-routing.module';
import { AppComponent } from 'app/shell/app/app.component';
import { HeaderComponent } from 'app/shell/header/header.component';
import { FooterComponent } from 'app/shell/footer/footer.component';
import { TextboxComponent } from 'app/common/components/controls/textbox/textbox.component';
import { InputMustEqualValidatorDirective } from 'app/common/components/controls/textbox/inputMustEqualValidator';
import { CheckboxComponent } from 'app/common/components/controls/checkbox/checkbox.component';
import { UploadComponent } from 'app/common/components/controls/upload/upload.component';
import { DropdownComponent } from 'app/common/components/controls/dropdown/dropdown.component';
import { RadiobuttonComponent } from 'app/common/components/controls/radiobutton/radiobutton.component';
import { LabelComponent } from 'app/common/components/controls/label/label.component';
import { HtmlComponent } from 'app/common/components/controls/html/html.component';
import { HtmlAlertComponent } from 'app/common/components/controls/htmlAlert/htmlAlert.component';
import { HtmlModalComponent } from 'app/common/components/controls/htmlModal/htmlModal.component';
import { IFrameComponent } from 'app/common/components/controls/iframe/iframe.component';
import { DevToolsComponent } from 'app/common/components/dev-tools/dev-tools.component';
import { ErrorDisplayComponent } from 'app/common/components/error-display/error-display.component';
import { MessageDisplayComponent } from 'app/common/components/message-display/message-display.component';
import { LoadingScreenDisplayComponent} from 'app/common/components/loadingscreen-display/loadingscreen-display.component';
import { MetadataRenderComponent } from 'app/common/components/metadata-render/metadata-render.component';
import { MetadataPageWrapperComponent } from 'app/common/components/metadata-page-wrapper/metadata-page-wrapper.component';
import { BankComponent } from 'app/pages/bank/bank.component';
import { BankMockDataProviderService } from 'app/pages/bank/mockData/bankMockDataProvider.service';
import { TaxComponent } from 'app/pages/tax/tax.component';
import { TaxMockDataProviderService } from 'app/pages/tax/mockData/taxMockDataProvider.service';
import { BasicProfileComponent } from 'app/pages/basic-profile/basic-profile.component';
import { BasicProfileMockDataProviderService } from 'app/pages/basic-profile/mockData/basicProfileMockDataProvider.service';
import { ComplianceComponent } from 'app/pages/compliance/compliance.component';
import { ComplianceMockDataProviderService } from 'app/pages/compliance/mockData/complianceMockDataProvider.service';
import { InvalidRouteComponent } from 'app/pages/invalid-route/invalid-route.component';
import { LoggedOutComponent } from 'app/pages/logged-out/logged-out.component';
import { MetadataApiService } from 'app/common/services/metadata-api.service';
import { FieldWatcherService } from 'app/common/services/field-watcher.service';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { AppSharedUtilityService } from 'app/common/services/app-shared-utility.service';
import { MetadataJsonSchemaValidatorService } from 'app/common/services/metadata-json-schema-validator.service';
import { InstrumentationService } from 'app/common/services/instrumentation.service';
import { LocalizationManagerService } from 'app/common/services/localization-manager.service';
import { AuthInterceptorService } from 'app/common/interceptors/auth-interceptor.service';
import { InstrumentationInterceptorService } from 'app/common/interceptors/instrumentation-interceptor.service';
import { RemoteMockDataInterceptorService } from 'app/common/interceptors/remote-mock-data-interceptor.service';
import { CustomErrorHandlerService } from 'app/common/services/custom-error-handler.service';
import { CommonMockMetadataApiService } from 'test-common/commonMockMetadataApiService';
import { ScriptInjectComponent } from 'app/common/components/script-inject/script-inject.component';
import { SessionManagerService } from 'app/common/services/session-manager.service';
import { MsalModule, MsalInterceptor, MsalService, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MSAL_GUARD_CONFIG, MsalInterceptorConfiguration, MsalGuardConfiguration } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { msalConfig } from './auth-config';
import { AuthInitService } from './auth-init.service';

// Common app objects used in app.module.ts and also in tests.

// Common declarations used by both the app and tests.
export const commonDeclarations: Array<Type<any> | any[]> = [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    TextboxComponent,
    InputMustEqualValidatorDirective,
    CheckboxComponent,
    UploadComponent,
    DropdownComponent,
    RadiobuttonComponent,
    LabelComponent,
    HtmlComponent,
    HtmlAlertComponent,
    HtmlModalComponent,
    IFrameComponent,
    DevToolsComponent,
    ErrorDisplayComponent,
    MessageDisplayComponent,
    LoadingScreenDisplayComponent,
    MetadataRenderComponent,
    MetadataPageWrapperComponent,
    BankComponent,
    TaxComponent,
    BasicProfileComponent,
    ComplianceComponent,
    InvalidRouteComponent,
    LoggedOutComponent,
    ScriptInjectComponent
];

// Common imports used by both the app and tests.
export const commonImports: Array<Type<any> | ModuleWithProviders<any> | any[]> = [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    // Initialize ng-bootstrap module.
    NgbModule,
    // Import Kendo modules.
    BrowserAnimationsModule,
    ButtonsModule,
    InputsModule,
    DateInputsModule,
    DropDownsModule,
    LabelModule,
    UploadModule,
    LayoutModule,
    GridModule,
    MsalModule
];

// Common providers used by both the app and tests.
export const commonProviders: Provider[] = [
    // The Bank/Tax/Basic/Compliance mock data provider services are in commonProviders because it is used with the local mock data
    // feature (not the same as unit tests). Unit tests, such as for bank and tax page, use their own mock data
    // providers. See spec files for those pages.
    BankMockDataProviderService, // See comment above.
    TaxMockDataProviderService, // See comment above.
    BasicProfileMockDataProviderService, // See comment above.
    ComplianceMockDataProviderService, // See comment above.
    // Tests (such as bank and tax pages) will provide their own mock MetadataApiService (see spec file for bank or
    // tax page to see an example). Those mock providers look like this:
    //   {
    //       provide: MetadataApiService,
    //       useValue: new BankMockMetadataApiService
    //   }
    MetadataApiService, // See comment above.
    FieldWatcherService,
    AppSharedStateService,
    AppSharedUtilityService,
    MetadataJsonSchemaValidatorService,
    InstrumentationService,
    LocalizationManagerService,
    SessionManagerService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptorService,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: InstrumentationInterceptorService,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: RemoteMockDataInterceptorService,
        multi: true
    },
    {
        provide: ErrorHandler,
        useClass: CustomErrorHandlerService
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
    },
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
    },
    {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    },
    MsalService,
    {
        provide: APP_INITIALIZER,
        useFactory: initializeApp,
        deps: [AuthInitService],
        multi: true
    }
];

// MSAL providers
export function MSALInstanceFactory(): PublicClientApplication {
    if (!msalConfig) {
        throw new Error('MSAL configuration is missing');
    }
    return new PublicClientApplication(msalConfig);
}

export function initializeApp(authInitService: AuthInitService) {
    return (): Promise<void> => authInitService.initialize();
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    // Add your protected resources here
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect
    };
}

// Test providers.
export const testProviders: Provider[] = commonProviders.concat([
    {
        provide: APP_BASE_HREF, useValue : '/'
    },
    {
        provide: POPUP_CONTAINER, // See: https://www.telerik.com/kendo-angular-ui/components/popup/api/POPUP_CONTAINER/
        useFactory: () => {
            // Return the container ElementRef, where the popup will be injected.
            return { nativeElement: document.body } as ElementRef;
        }
    }
    // Note, we are not using this ComponentFixtureAutoDetect provider. If this is enabled at provider creation time, then
    // calls to create components (ex: fixture = TestBed.createComponent(TextboxComponent);) will cause Angular component lifecycle (ngOnInit, etc)
    // to be called during component creation. We want to defer that until @Inputs are set in the tests. Once inputs are set, then
    // manually turn on auto change detection using: fixture.autoDetectChanges(true);
    //   {
    //       provide: ComponentFixtureAutoDetect, // See: https://ngdocsdev.firebaseapp.com/docs/ts/latest/guide/testing.html#!#auto-detect-changes
    //       useValue: true
    //   }
]);

// Test providers with the common mock metadata api service added.
export const testProvidersWithCommonMockMetadataApiService: Provider[] = testProviders.concat([
    {
        provide: MetadataApiService,
        useValue: new CommonMockMetadataApiService()
    }
]);
