// Extending JSON to add prune. Used in prettifyJson().
// This is using JSON.prune.js (in external-scripts folder).
// See: https://github.com/Canop/JSON.prune
interface JSONEx extends JSON {
    prune(json: any): string;
}

// Misc utility class. Contains general purpose helper functions.
export class MiscUtil {
    private static guidPattern: string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

    // Check if variable is null or undefined. Not checking for empty string or 0 (both are falsey).
    public static isNullOrUndefined(x?: any): boolean {
        if (x === null) {
            return true;
        } else if (x === undefined) {
            return true;
        }
        return false;
    }

    // Check if variable is null or undefined or empty string. Does not check for 0 (which is falsey).
    public static isNullOrUndefinedOrEmptyString(x?: any): boolean {
        if (MiscUtil.isNullOrUndefined(x)) {
            return true;
        } else if (typeof x === 'string' && x.trim() === '') {
            return true;
        }
        return false;
    }

    // Check if object has a named property.
    // Not checking if the property falsey (null, undefined, empty string, or 0) - just checking if the property exists on the object.
    public static hasProperty(obj: Object, propName: string): boolean {
        return obj.hasOwnProperty(propName);
    }

    // Makes JSON look pretty.
    public static prettifyJson(json: any, prune?: boolean): string {
        if (prune === null || prune === undefined) {
            prune = false;
        }

        if (prune) {
            const prunedJson: string = (<JSONEx>JSON).prune(json);
            json = JSON.parse(prunedJson);
        }

        const jsonStr = JSON.stringify(json, null, 4);
        return jsonStr;
    }

    // Helper function to wait for a condition to be true and then calls a callback.
    // If the stepIntervals count is reached then it will also call the callback. By default, the stepMilliseconds is 100 (or 1/10th of a second),
    // the stepIntervals is 20 which means it will wait up to 2 seconds.
    public static waitFn(waitForConditionToBeTrue: () => boolean, callback: () => void, stepMilliseconds: number = 100, stepIntervals: number = 20, stepInterval: number = 0): void {
        if (waitForConditionToBeTrue() || stepInterval > stepIntervals) {
            callback();
        } else {
            stepInterval++;
            setTimeout(() => {
                MiscUtil.waitFn(waitForConditionToBeTrue, callback, stepMilliseconds, stepIntervals, stepInterval);
            }, stepMilliseconds);
        }
    }

    // Get a query string value for specified key.
    // See: https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
    public static getQueryStringValue(key: string): string {
        key = key.replace(/[\[\]]/g, '\\$&'); // CodeQL [SM03944] False Positive: The regex is safe and correctly escapes special characters.
        const regex: RegExp = new RegExp('[?&]' + key + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(window.location.href);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    // Create a new guid.
    public static createGuid(): string {
        return MiscUtil.guidPattern.replace(/[xy]/g, c => {
            // tslint:disable-next-line:no-bitwise
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}
